import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Select } from "antd";
import JoditEditor from "jodit-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useAppSelector } from "src/hooks";

import { downloadProjectPdf, shareProjectWithCustomer } from "src/api/backend-endpoints";
import { SolarImage } from "src/assets/images";
import { LongLogo } from "src/assets/svgs";
import { ApiAxiosClient } from "src/axios";
import useDownloadPdf from "src/pages/ApplicationPage/hooks/useDownloadPdf";
import { PopupSkeleton } from "src/shared/components";
import { PrimaryButton } from "src/shared/StyledComponents";

import { InstallerPartner } from "../WelcomePopupForNoAddress/components/MiddlePath/MiddlePath.styles";

import cssClasses from "./savedProjectUserDetail.module.css";

import { ProjectActions } from "src/redux/actionCreators";
import {
  selectors as PopupSelectors,
  actionCreators as PopupActions,
  POPUP_KEYS,
} from "src/redux/popups";
import { updateProject } from "src/redux/project/action/action.creators";
import { getAddress, getProject } from "src/redux/project/selectors";
import { UserInfo } from "src/redux/project/types/project-state";
import { ProjectSelectors, UserSelectors } from "src/redux/selectors";
import { getInstaller } from "src/redux/user/selectors";

interface ISendofferPopupContentProps {
  setShowSendMailPopup: (arg: boolean) => void;
  handleShareProject: (e: any) => void;
  emailContent: string;
  setEmailContent: (arg: string) => void;
  sendPdfWithEmail: boolean;
  setSendPdfWithEmail: (arg: boolean) => void;
  loader: boolean;
}

const NewEditor = ({ setEmailContent }: any) => {
  const [content, setContent] = useState("");
  const { t } = useTranslation();
  const [templateOptions, setTemplateOptions] = useState([]);
  const installerDetails = useAppSelector(getInstaller);

  const fetchTemplates = useCallback(async () => {
    try {
      if (!installerDetails?._id) return;

      const response = await ApiAxiosClient.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/email-template/${installerDetails?._id}`,
      );
      setTemplateOptions(
        response.data.data.map((template: any) => ({
          value: template.content,
          label: template.templateName,
        })),
      );
    } catch (error) {
      toast.error("An error occurred while fetching the templates.");
      console.error(error);
    }
  }, []);

  useEffect(() => {
    setEmailContent(content);
  }, [content]);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const config = useMemo(() => {
    const joditConfigs = {
      readonly: false,
      placeholder: "",
      uploader: {
        insertImageAsBase64URI: true,
      },
      language: "de",
      buttons:
        "bold,underline,eraser,ul,ol,font,fontsize,image,video,cut,copy,paste,copyformat,table,link,indent,left,undo,redo",
    };
    return joditConfigs;
  }, []);

  return (
    <div className="grid gap-3">
      {installerDetails?._id && (
        <div className="mx-auto">
          <Select
            placeholder={t("Select Template")}
            options={templateOptions}
            onChange={(value: string) => setContent(value)}
            style={{ width: 400, textAlign: "center" }}
          />
        </div>
      )}
      <JoditEditor
        value={content}
        config={config}
        onBlur={(newContent: any) => setContent(newContent)}
      />
    </div>
  );
};

const SendOfferPopupContent: React.FC<ISendofferPopupContentProps> = ({
  setShowSendMailPopup,
  handleShareProject,
  emailContent,
  setEmailContent,
  sendPdfWithEmail,
  setSendPdfWithEmail,
  loader,
}) => {
  const { t } = useTranslation();
  const completeAddress = useSelector(ProjectSelectors.getCompleteAddress);

  const onCancelClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setShowSendMailPopup(false);
    },
    [setShowSendMailPopup],
  );

  const handleCheckboxChange = useCallback(
    (e, checked) => {
      e.preventDefault();
      e.stopPropagation();
      setSendPdfWithEmail(checked);
    },
    [setSendPdfWithEmail],
  );

  return (
    <div className={cssClasses.headerOuterContainer}>
      <div className={cssClasses.headerInnerContainer}>
        <LongLogo />
        <div className={cssClasses.headerTitle}>
          {t("Send the offer to your customer per mail")}
        </div>
        <div className={cssClasses.formSubtitle}>{completeAddress}</div>
        <NewEditor setEmailContent={setEmailContent} />
        <FormControlLabel
          control={
            <Checkbox
              checked={sendPdfWithEmail}
              onChange={handleCheckboxChange}
              defaultChecked
              disableFocusRipple
              disableRipple
              disableTouchRipple
            />
          }
          label={<div className={cssClasses.checkboxText}>{t("Add PDF to E-Mail")}</div>}
        />
        <div className={cssClasses.buttonContainer}>
          <button
            className={cssClasses.primaryButton}
            onClick={handleShareProject}
            disabled={loader}
          >
            {loader ? t("Sending...") : t("Send to customer")}
          </button>
          <button className={cssClasses.secondaryButton} onClick={onCancelClick}>
            {t("cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

const SavedProjectUserDetailPopup: React.FC = () => {
  const [showSendMailPopup, setShowSendMailPopup] = useState(false);
  const [sendPdfWithEmail, setSendPdfWithEmail] = useState(false);
  const [emailContent, setEmailContent] = useState("");
  const mapboxAddress = useSelector(getAddress);
  const project = useSelector(getProject);
  const pdfData = useDownloadPdf();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!setShowSendMailPopup) {
      setEmailContent("");
      setSendPdfWithEmail(false);
    }
  }, [showSendMailPopup]);

  const { t } = useTranslation();
  const disptach = useDispatch();
  const instalelrParnter = useSelector(getInstaller);

  const [formData, setFormData] = useState<UserInfo>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const completeAddress = useSelector(ProjectSelectors.getCompleteAddress);
  const user = useSelector(UserSelectors.getUser);
  const projectUserInfo = useSelector(ProjectSelectors.getUserInfo);

  const onCloseClick = useCallback(() => {
    disptach(PopupActions.closePopup(POPUP_KEYS.saved_project_user_info));
  }, [disptach]);

  useEffect(() => {
    if (projectUserInfo) {
      setFormData({
        firstName: projectUserInfo.firstName,
        lastName: projectUserInfo.lastName,
        email: projectUserInfo.email,
        phoneNumber: projectUserInfo.phoneNumber,
      });
    } else if (user) {
      setFormData({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
      });
    }
  }, [user, projectUserInfo]);

  const handleUpdateFormData = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    },
    [],
  );

  const handleSave = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      disptach(ProjectActions.addUserInfo(formData));
      toast.success(t("User info saved successfully"));
      onCloseClick();
    },
    [disptach, formData, t, onCloseClick],
  );

  const handleShareProject = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      setLoader(true);
      try {
        let url = "";
        console.log({ sendPdfWithEmail });
        if (sendPdfWithEmail) {
          const pdfName = `Solarprojekt-SolarHub-${mapboxAddress?.address} ${
            mapboxAddress?.postcode
          }-${project?.shortId ? project?.shortId : ""}`;
          const response = await ApiAxiosClient.post(
            `${downloadProjectPdf.url}/?templateId=${process.env.REACT_APP_TEMPLATE_ID}&fileName=${pdfName}`,
            pdfData,
          );
          url = response.data.url;
          disptach(updateProject({ ...project, projectPdf: url }));
          disptach(ProjectActions.addUserInfo(formData));
          const payload = {
            name: `${formData.firstName} ${formData.lastName}`,
            projectLink: location.href,
            address: completeAddress,
            email: formData.email,
            iid: instalelrParnter?._id,
            emailContent,
            pdf: url,
          };
          if (!instalelrParnter?._id) {
            delete payload.iid;
          }
          await ApiAxiosClient.post(shareProjectWithCustomer.url, payload);
          setLoader(false);
          toast.success(t("The project details have been successfully sent to the user."));
        } else {
          disptach(ProjectActions.addUserInfo(formData));
          const payload = {
            name: `${formData.firstName} ${formData.lastName}`,
            projectLink: location.href,
            address: completeAddress,
            email: formData.email,
            iid: instalelrParnter?._id,
            emailContent,
            pdf: url,
          };
          if (!instalelrParnter?._id) {
            delete payload.iid;
          }
          await ApiAxiosClient.post(shareProjectWithCustomer.url, payload);
          setLoader(false);
          toast.success(t("The project details have been successfully sent to the user."));
        }
      } catch (error) {
        setLoader(false);
        toast.error(t("An error occurred while sending the project details to the user."));
      }
      onCloseClick();
    },
    [
      onCloseClick,
      sendPdfWithEmail,
      disptach,
      formData,
      completeAddress,
      instalelrParnter?._id,
      emailContent,
      t,
      mapboxAddress?.address,
      mapboxAddress?.postcode,
      project,
      pdfData,
    ],
  );

  const handleOpenSendMailPopup = useCallback(() => {
    setShowSendMailPopup(true);
  }, []);

  return (
    <PopupSkeleton
      handleClose={onCloseClick}
      open={true}
      styless={{ height: "100%", maxHeight: "fit-content" }}
      styleToDialog={{ zIndex: 2000 }}
    >
      <div className={cssClasses.popupContainer}>
        {showSendMailPopup ? (
          <SendOfferPopupContent
            setShowSendMailPopup={setShowSendMailPopup}
            handleShareProject={handleShareProject}
            emailContent={emailContent}
            setEmailContent={setEmailContent}
            sendPdfWithEmail={sendPdfWithEmail}
            setSendPdfWithEmail={setSendPdfWithEmail}
            loader={loader}
          />
        ) : (
          <>
            <div className={cssClasses.popupImage}>
              <img src={SolarImage} />
            </div>
            <div className={cssClasses.popupFormContainer}>
              <div className={cssClasses.formHeader}>
                <LongLogo />
              </div>
              <div className={cssClasses.formTitle}>{t("Contact details project")}</div>
              <div className={cssClasses.formSubtitle}>{completeAddress}</div>

              <div className={cssClasses.formContainer}>
                <TextField
                  fullWidth
                  name="firstName"
                  id="firstname-form-field"
                  label={t("First Name")}
                  variant="outlined"
                  size="small"
                  value={formData.firstName}
                  onChange={handleUpdateFormData}
                />
                <TextField
                  fullWidth
                  name="lastName"
                  id="lastname-form-field"
                  label={t("Last Name")}
                  variant="outlined"
                  size="small"
                  value={formData.lastName}
                  onChange={handleUpdateFormData}
                />
                <TextField
                  fullWidth
                  name="email"
                  id="email-form-field"
                  label={t("E-Mail")}
                  variant="outlined"
                  size="small"
                  value={formData.email}
                  onChange={handleUpdateFormData}
                />
                <TextField
                  fullWidth
                  name="phoneNumber"
                  id="phone-form-field"
                  label={t("Phone")}
                  variant="outlined"
                  size="small"
                  value={formData.phoneNumber}
                  onChange={handleUpdateFormData}
                />
                <div className={cssClasses.buttonContainer}>
                  <button className={cssClasses.primaryButton} onClick={handleSave}>
                    {t("Save")}
                  </button>
                  <button className={cssClasses.secondaryButton} onClick={handleOpenSendMailPopup}>
                    {t("Send project details")}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </PopupSkeleton>
  );
};

export default SavedProjectUserDetailPopup;
