import { cloneDeep } from "lodash";

import { getUserPreferredLanguageShort } from "src/utils";

import { UserAuthStatus } from "./types";

import { ReduxAction } from "src/redux/common.types";
import { actionNames, handlers, UserState } from "src/redux/user";

const INITIAL_STATE: UserState = {
  user: null,
  installer: null,
  rantingData: null,
  isAuthStateLoading: false,
  language: getUserPreferredLanguageShort(),
  authStatus: UserAuthStatus.initial,
  subsidy: null
};

const userReducer = (state = INITIAL_STATE, action: ReduxAction): UserState => {
  switch (action.type) {
    case actionNames.SET_USER:
      return handlers.setUser(state, action);
    case actionNames.SET_INSTALLER:
      return handlers.setInstaller(state, action);
    case actionNames.SET_RENTING_DATA:
      return handlers.setRantingData(state, action);
    case actionNames.SET_USER_AUTH_LOADING:
      return handlers.setIsAuthLoading(state, action);
    case actionNames.SET_LANGUAGE:
      return handlers.setLanguage(state, action);
    case actionNames.UPDATE_LAST_LOG:
      return handlers.updateLastLog(state, action);
    case actionNames.USER_AUTH_STATUS:
      return handlers.setUserAuthStatus(state, action);
    case actionNames.SET_SIMPLIFY:
      return handlers.setSimplify(state, action);
    case actionNames.SET_SUBSIDY:
      return handlers.setSubsidy(state, action);
    case "RESET_REDUX":
      return cloneDeep(INITIAL_STATE);
    default:
      return state;
  }
};

export default userReducer;
