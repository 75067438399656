import styled from "styled-components/macro";

export const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;
  align-items: center;
  gap: 2rem;
  padding-inline: 10px;
  min-width: 40vw;
  max-width: 70vw;
  font-family: "NuNito";
`;

export const Section1 = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  color: #2d4764;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  align-items: center;
`;

export const Section2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 24px;
  font-weight: 600;
  color: #2d4764;
  width: fit-content;
  justify-content: center;
`;

export const Button = styled.div`
  align-self: center;
`;

export const text = styled.div`

`;