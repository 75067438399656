export interface InstallerDB {
  _id: string;
  address: string;
  iid: string;
  companyUrl: string;
  LastUpdate: number;
  Muttergesellschaft: string;
  "Netzanlagen/Inselanlagen": string;
  userRating: number;
  email: string;
  WechselrichterLieferanten: string;
  installerStatus: boolean;
  lat: number;
  lng: number;
  adminRating: number;
  logoUrl: string[];
  InstallaliertSeit: string;
  geohash: string;
  companyName: string;
  geometry: Geometry;
  selfLeads: any[];
  isRegistered: boolean;
  InstallationenIn: string;
  website: string;
  InstallationSizeIn: string;
  SonstigerService: string;
  SolarPanelSuppliers: string;
  telephone: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  dataPrivacyURL: string;
  distance: number;
  distanceInKm: number;
  partnerFrontEnd: boolean;
  partnerSlug: string;
  appointmentURL: string;
  whichOptionSelected: string;
  isRentingAvalible: boolean;
  isAllowHitPump: boolean;
  isAllowWebinar?: boolean;
  addressSelectorText: string;
  isUploadImageRequired: boolean;
  realizationWithIn6Weeks?: string;
  addressSelectorDescription?: string;
  videoTitle?: string;
  addressSelectorStep1Title?: string;
  addressSelectorStep2Title?: string;
  addressSelectorStep3Title?: string;
  addressSelectorStep1Description?: string;
  addressSelectorStep2Description?: string;
  addressSelectorStep3Description?: string;
  installationConfirmationTitle?: string;
  installationConfirmationText?: string;
  installationConfirmationSubText?: string;
  welcomeVideo?: string;
  deactivateVideo?: boolean;
  pvPlanModalText?: string;
  isOnlySmallSolarhubLogo?: boolean;
  constants?: Iconstant[];
}
interface Iconstant {
  isActive: boolean;
  isStandard: boolean;
  type: string;
  priceB2b: number;
  priceB2c: number;
  title: string;
  _id: string;
}
interface Geometry {
  type: string;
  coordinates?: number[] | null;
}

export enum OfferType {
  PDF = "pdf",
  REDIRECT = "redirect",
}
