import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  formatNumberByLang,
  useIsComponentAdded,
  useNumberFormat,
  useValidPreferenceMap,
  useYearlySystemProfit,
} from "src/hooks";

import { getAverageCapacity, getCheapestProduct } from "src/helpers";
import { getFormattedDate } from "src/pdf/Utils/helperMethods";
import {
  getMonthlyEnergyDetails,
  labelsGerman,
} from "src/popups/ChartsTabs/MonthlyBalance/MonthlyPersentage";
import { getPercentage, isValidURL } from "src/utils";

import { getChartRecordsFromModel } from "../components/ApplicationStep1/components/ImageConfiguration/ConsumptionCharts/components/ElectricitySupplyChart/ElectricitySupplyChartRecords";

import { getApplicaitonFilters } from "src/redux/application/selector";
import { getAddress, getModel, getProject, getQuoteProfile } from "src/redux/project/selectors";
import { ProductSelectors, UserSelectors } from "src/redux/selectors";
import { getUser } from "src/redux/user/selectors";



const useDownloadPdf = () => {
  const project = useSelector(getProject);
  const user = useSelector(getUser);
  const date = getFormattedDate();
  const hasPartner = window.location.href.includes("partner");
  const installer = useSelector(UserSelectors.getInstaller);
  const quoteProfile = useSelector(getQuoteProfile);
  const address = useSelector(getAddress);
  const filtered = useSelector(ProductSelectors.getAllFilteredProducts);
  const cheapestSolarPanel = useMemo(() => {
    return getCheapestProduct(filtered.solarPanels);
  }, [filtered]);
  const { isSolarPanelAdded, isInvertedAdded, isHeatpumpAdded, isWallboxAdded, isBatteryAdded } =
    useIsComponentAdded();
  const { profitByYear } = useYearlySystemProfit();
  const { formatNumber } = useNumberFormat();
  const model = useSelector(getModel);
  const chartRecords = getChartRecordsFromModel(model);
  const { t } = useTranslation();

  const totalProjectCost = project?.financialDetails?.costs?.totalProjectCost;
  const monthlyEnergies = getMonthlyEnergyDetails();

  const filteredImages = useMemo(() => {
    return quoteProfile?.imgURLs.filter((img) => {
      const fileExtension = img?.split(".").pop()?.toLowerCase();
      if (fileExtension) {
        const isInvalidExtension = ["obj", "mtl", "pvprj"].includes(fileExtension);
        if (isInvalidExtension) {
          return false;
        }
        if (img.includes("Destination") && isValidURL(img)) {
          if (address.city === "Gundelfingen") {
            return !img.includes("Baked") && isValidURL(img);
          } else {
            return img.includes("Screenshot");
          }
        }
        return isValidURL(img);
      }
    });
  }, [address, quoteProfile.imgURLs]);

  const getImageUrl = useMemo(() => {
    return filteredImages.length ? filteredImages[0] : "";
  }, [filteredImages]);

  const solarStorage = cheapestSolarPanel?.applicableMaxCapacity
    ? (cheapestSolarPanel?.applicableMaxCapacity / 1000) * project?.components?.solarPanel?.quantity
    : 0;

  const cheapestinverters = useMemo(() => {
    return getCheapestProduct(filtered.inverters);
  }, [filtered]);

  const cheapestbatteries = useMemo(() => {
    return getCheapestProduct(filtered.batteries);
  }, [filtered]);

  const cheapestwallboxes = useMemo(() => {
    return getCheapestProduct(filtered.wallboxes);
  }, [filtered]);

  const cheapestheatpumps = useMemo(() => {
    return getCheapestProduct(filtered.heatpumps);
  }, [filtered]);
  const { profit, electricityBillSavingsTillNow, feedInSavingsTillNow, gasOilBillSavingsTillNow, heatpumpGridCostTillNow} = profitByYear(
    project.financialDetails.expectedReturnTime,
  );
  const loss = electricityBillSavingsTillNow;
  const capacity = useMemo(() => {
    return getAverageCapacity(filtered.solarPanels);
  }, [filtered.solarPanels]);
  const solarPanel = project?.components?.solarPanel;
  let systemCapacity = (capacity * solarPanel.quantity) / 1000;
  if (solarPanel?.item) {
    systemCapacity = (Number(solarPanel?.item.capacity) * solarPanel.quantity) / 1000;
  }

  const houseAreaChartData = useMemo(() => {
    const houseAreaData = [];
    for (let i = 0; i <= 11; i++) {
      houseAreaData.push({
        month: `${labelsGerman[i]}`,
        nez: monthlyEnergies[i].gridSupply,
        ein: monthlyEnergies[i].feedIn,
        pv: monthlyEnergies[i].pvProduction,
        jah: monthlyEnergies[i].consumption,
      });
    }
    return houseAreaData;
  }, [monthlyEnergies]);
  const leftPercent = getPercentage(model.fromPVAndBattery, model.feedIn + model.fromPVAndBattery);
  const calculatedCost =
    project.financialDetails.costs.totalProjectCost / project.energy.solarPanelProduction * 25;

  const investMentChartData = [...new Array(project.financialDetails.expectedReturnTime)].map(
    (_,index) => ({
      profit: profitByYear(index + 1).profit,
      year: new Date().getFullYear() + index,
    }),
  );
  
  const defaultFilters = useSelector(getApplicaitonFilters);
  const unfilteredProductsReduxSolarPanel = useSelector(ProductSelectors.getSolarPanels);
  const unfilteredProductsReduxInverters = useSelector(ProductSelectors.getInverters);
  const unfilteredProductsReduxBatteries = useSelector(ProductSelectors.getBatteries);
  const unfilteredProductsReduxHeatPumps = useSelector(ProductSelectors.getHeatpumps);
  const unfilteredProductsReduxWallBoxes = useSelector(ProductSelectors.getWallboxes);

  const unfilteredProductsSolarPanel = useMemo(() => {
    return unfilteredProductsReduxSolarPanel;
  }, [unfilteredProductsReduxSolarPanel]);

  const unfilteredPossibilitySolarPanel = useValidPreferenceMap({
    preferences: defaultFilters,
    products: unfilteredProductsSolarPanel,
  });

  const unfilteredProductsInverters = useMemo(() => {
    return unfilteredProductsReduxInverters;
  }, [unfilteredProductsReduxInverters]);

  const unfilteredPossibilityInverters = useValidPreferenceMap({
    preferences: defaultFilters,
    products: unfilteredProductsInverters,
  });

  const unfilteredProductsBattery = useMemo(() => {
    return unfilteredProductsReduxBatteries;
  }, [unfilteredProductsReduxBatteries]);

  const unfilteredPossibilityBattery = useValidPreferenceMap({
    preferences: defaultFilters,
    products: unfilteredProductsBattery,
  });

  const unfilteredProductsHeatPump = useMemo(() => {
    return unfilteredProductsReduxHeatPumps;
  }, [unfilteredProductsReduxHeatPumps]);

  const unfilteredPossibilityHeatPump = useValidPreferenceMap({
    preferences: defaultFilters,
    products: unfilteredProductsHeatPump,
  });

  const unfilteredProductsWallBox = useMemo(() => {
    return unfilteredProductsReduxWallBoxes;
  }, [unfilteredProductsReduxWallBoxes]);

  const unfilteredPossibilityHeatWallBox = useValidPreferenceMap({
    preferences: defaultFilters,
    products: unfilteredProductsWallBox,
  });

  const solarPanelPreference = useMemo(() => (Object.entries(unfilteredPossibilitySolarPanel))
  .filter(([key, { canBeChosen }]) => {
    return canBeChosen && key !== "applicableMaxCapacity";
  }).map((key: any) =>  key?.[0] || ""), [t, unfilteredPossibilitySolarPanel]) 

  const inverterPreference = useMemo(() => (Object.entries(unfilteredPossibilityInverters))
  .filter(([key, { canBeChosen }]) => {
    return canBeChosen && key !== "applicableMaxCapacity";
  }).map((key: any) =>  key?.[0] || ""), [t, unfilteredPossibilityInverters]) 
  
  const batteryPreference = useMemo(() => (Object.entries(unfilteredPossibilityBattery))
  .filter(([key, { canBeChosen }]) => {
    return canBeChosen && key !== "applicableMaxCapacity";
  }).map((key: any) =>  key?.[0] || ""), [t, unfilteredPossibilityBattery]) 

  const heatpumpPreference = useMemo(() => (Object.entries(unfilteredPossibilityHeatPump))
  .filter(([key, { canBeChosen }]) => {
    return canBeChosen && key !== "applicableMaxCapacity";
  }).map((key: any) =>  key?.[0] || ""), [t, unfilteredPossibilityHeatPump]) 

  const wallBoxesPreference = useMemo(() => (Object.entries(unfilteredPossibilityHeatWallBox))
  .filter(([key, { canBeChosen }]) => {
    return canBeChosen && key !== "applicableMaxCapacity";
  }).map((key: any) =>  key?.[0] || ""), [t, unfilteredPossibilityHeatWallBox]);

  const solarPanelImage = useMemo(() => solarPanel?.item ? `${solarPanel?.item?.imgUrls?.[0]}?query=${new Date()}` : `${cheapestSolarPanel?.imgUrls?.[0]}?query=${new Date()}`, [cheapestSolarPanel?.imgUrls, solarPanel?.item]);
  const inverterImage = useMemo(() => project.components.inverter?.item ? `${project.components.inverter?.item?.imgUrls?.[0]}?query=${new Date()}` : `${cheapestinverters?.imgUrls?.[0]}?query=${new Date()}`, [cheapestinverters?.imgUrls, project.components.inverter?.item]);
  const batteryImage = useMemo(() => project.components.battery?.item ? `${project.components.battery?.item?.imgUrls?.[0]}?query=${new Date()}` : `${cheapestbatteries?.imgUrls?.[0]}?query=${new Date()}`, [cheapestbatteries?.imgUrls, project.components.battery?.item]);
  const heatPumpImage = useMemo(() => project.components.heatpump?.item ? `${project.components.heatpump?.item?.imgUrls?.[0]}?query=${new Date()}` : `${cheapestheatpumps?.imgUrls?.[0]}?query=${new Date()}`, [cheapestheatpumps?.imgUrls, project.components.heatpump?.item]);
  const wallBoxImage = useMemo(() => project.components.wallbox?.item ? `${project.components.wallbox?.item?.imgUrls?.[0]}?query=${new Date()}` : `${cheapestwallboxes?.imgUrls?.[0]}?query=${new Date()}`, [cheapestwallboxes?.imgUrls, project.components.wallbox?.item]);

  const solarPanelPrice = solarPanel?.item ? (project.type === "b2c" ? solarPanel?.item?.priceB2c : solarPanel?.item?.priceB2b)  : ( project.type === "b2c" ? cheapestSolarPanel?.priceB2c : cheapestSolarPanel?.priceB2b);
  const inverterPrice = project.components.inverter?.item ? (project.type === "b2c" ? project.components.inverter?.item?.priceB2c : project.components.inverter?.item?.priceB2b)  : ( project.type === "b2c" ? cheapestinverters?.priceB2c : cheapestinverters?.priceB2b);
  const batteryPrice = project.components.battery?.item ? (project.type === "b2c" ? project.components.battery?.item?.priceB2c : project.components.battery?.item?.priceB2b)  : ( project.type === "b2c" ? cheapestbatteries?.priceB2c : cheapestbatteries?.priceB2b);
  const heatPumpPrice = project.components.heatpump?.item ? (project.type === "b2c" ? project.components.heatpump?.item?.priceB2c : project.components.heatpump?.item?.priceB2b)  : ( project.type === "b2c" ? cheapestheatpumps?.priceB2c : cheapestheatpumps?.priceB2b);
  const wallBoxPrice = project.components.wallbox?.item ? (project.type === "b2c" ? project.components.wallbox?.item?.priceB2c : project.components.wallbox?.item?.priceB2b)  : ( project.type === "b2c" ? cheapestwallboxes?.priceB2c : cheapestwallboxes?.priceB2b);
  const combinedImaes = useMemo(() => {
    const imageData = [
      ...project.images?.houseTop?.imageURLs,
      ...project.images?.houseBottom?.imageURLs,
      ...project.images?.electricMeter?.imageURLs,
    ]

    return  imageData.map((image: any) => {
      if(typeof image === "string"){
        return image;
      }
      if(typeof image === "object") {
        return image?.url;
      }
      return image;
    })
},[project.images]);

const combinedDescription =
project.images?.houseTop?.description +
" " +
project.images?.houseBottom?.description +
" " +
project.images?.electricMeter?.description;

  const data = {
    customerName: `${user?.firstName} ${user?.lastName}`,
    customerAddress: `${project.quote.mapboxAddress.complete.replaceAll(", Germany", "")}`,
    date,
    logo: installer?.logoUrl[0] ?? "https://solarhub-medias-staging.s3.us-east-1.amazonaws.com/installers/65fd36bbd8266255584b7559/Logo%20light%20new%20blue.png",
    shortId: `${project?.shortId}-${project?.version}`,
    contactPersonInstaller: hasPartner
      ? installer?.address?.replace(", Germany", "")
      : "Daniel Watz",
    companyNameInstaller: hasPartner ? installer?.companyName : "SolarHub GmbH",
    phoneNrIInstaller: hasPartner ? installer?.telephone : "+49 761 87003418",
    emailInstaller: hasPartner ? installer?.email : "experte@solarhub24.de",
    projectImage: `${getImageUrl}?query=${new Date().getTime()}`,
    photo: formatNumberByLang(solarStorage.toFixed(0)),
    batterieStor: formatNumberByLang(
      ((cheapestbatteries?.applicableMaxCapacity ?? 0) / 1000).toFixed(0),
    ),
    energieManagemtSystem: isInvertedAdded ? "Energiemanagemtsystem" : "",
    heizSystem: isHeatpumpAdded ? "Heizsystem" : "",
    ladeStation: isWallboxAdded ? "Ladestation" : "",
    yourInvest: formatNumberByLang(totalProjectCost.toFixed(0)),
    profitChart: {
      profit: `${-profit}`,
      loss: `${-loss}`,
    },
    totalCon: formatNumberByLang(Math.round(model.consumption).toFixed(0), "de"),
    houseCon: formatNumberByLang(Math.round(model.household.consumption).toFixed(0), "de"),
    generation: formatNumber(project?.energy?.solarPanelProduction.toFixed(0)),
    eMobCon: isWallboxAdded ? formatNumberByLang(model.ev.consumption.toFixed(0)) : "",
    heatCon: isHeatpumpAdded ? formatNumberByLang(model.ev.consumption.toFixed(0)) : "",
    gs: formatNumberByLang(model.fromGrid.toFixed(0)),
    fi: formatNumberByLang(model.feedIn.toFixed(0)),
    co2: formatNumber(systemCapacity * 330),
    heatArea: houseAreaChartData,
    energyChart: {
      verbrauch: getPercentage(model.fromPVAndBattery, model.feedIn + model.fromPVAndBattery).toFixed(0),
      warmepumpe: (100 - getPercentage(model.fromPVAndBattery, model.feedIn + model.fromPVAndBattery)).toFixed(0),
      verbrauchTitle: `${t("self-supply")} ${formatNumber(model.fromPVAndBattery.toFixed(0)) ?? 0} kWh (${getPercentage(model.fromPVAndBattery, model.feedIn + model.fromPVAndBattery).toFixed(0)}%)`,
      warmepumpeTitle: `${t("feed-in")} ${formatNumber(model.feedIn.toFixed(0)) ?? 0} kWh (${formatNumber(100 - parseInt(String(getPercentage(model.fromPVAndBattery, model.feedIn + model.fromPVAndBattery))))}%)`,
      autarky: model.percentages.autarky.toFixed(0),
    },
    batteryChart: {
      pvTitle: `${t(chartRecords[0].label.mainLabel)} ${chartRecords[0].label.subLine}`,
      pv: chartRecords[0].value,
      batteryTitle: `${t(chartRecords[2].label.mainLabel)} ${chartRecords[2].label.subLine}`,
      battery: chartRecords[2].value,
      netTitle: `${t(chartRecords[1].label.mainLabel)} ${chartRecords[1].label.subLine}`,
      net: chartRecords[1].value,
      autarky: model.percentages.autarky.toFixed(0),
    },
    ssgen: formatNumberByLang(project.energy.solarPanelProduction.toFixed(0)),
    ssBat: formatNumberByLang(model.fromBattery.toFixed(0)),
    baPer: formatNumberByLang(model.percentages.fromBattery.toFixed(0)),
    grid: formatNumberByLang(model.feedIn.toFixed(0)),
    directCons: formatNumberByLang(model.fromPV.toFixed(0)),
    directPer: formatNumberByLang(model.percentages.fromPV.toFixed(0)),
    issgen: formatNumberByLang(model.fromPV.toFixed(0)),
    ids: formatNumberByLang(model.percentages.fromPV.toFixed(0)),
    ibaPer: formatNumberByLang(model.percentages.fromBattery.toFixed(0)),
    issBat: formatNumberByLang(model.fromBattery.toFixed(0)),
    igrid: formatNumberByLang(model.fromGrid.toFixed(0)),
    igridPer: formatNumberByLang(model.percentages.fromGrid.toFixed(0)),
    tCons: formatNumberByLang((model.fromPV + model.fromBattery + model.fromGrid).toFixed(0)),
    dePer: leftPercent.toFixed(0),
    ugPer: model.percentages.autarky.toFixed(0),
    pwss: formatNumberByLang(profit.toFixed(0)),
    ay: formatNumberByLang(project.financialDetails.amortizationTime.toFixed(0)),
    modulanzahl: solarPanel.quantity,
    jahre: formatNumberByLang(project.energy.solarPanelProduction.toFixed(0)),
    verb: formatNumberByLang(project.household.consumption.toFixed(0)),
    ph: solarStorage.toFixed(0),
    ba: formatNumberByLang(((cheapestbatteries?.applicableMaxCapacity ?? 0) / 1000).toFixed(0)),
    waterHeat: project?.components?.waterHeating ? "Heizstab" : "",
    dach: project.energyProductionParams?.roofPitch,
    ihrsol: formatNumber(calculatedCost.toFixed(0)),
    yearlyProfit: investMentChartData,
    "ht": "Einsparungen Öl/Gas",
    "htp": `+ ${formatNumberByLang(gasOilBillSavingsTillNow.toFixed(0))} €`,
    "htlt": "Netzbezug E-Mobilität",
    "htl": `- ${formatNumberByLang(heatpumpGridCostTillNow.toFixed(0))} €`,
    "feedIn": `+ ${formatNumberByLang(feedInSavingsTillNow.toFixed(0))} €`,
    "elebill": `+ ${formatNumberByLang(electricityBillSavingsTillNow.toFixed(0))} €`,
    "cost":  `- ${formatNumberByLang(project.financialDetails.costs.appliedProjectCost.toFixed(0))} €`,
    po1t: formatNumber((solarPanelPrice ?? 0 * solarPanel.quantity).toFixed(0)),
    po1q: project.components.solarPanel.quantity,
    po1p: solarPanelPrice,
    po2t: formatNumber((inverterPrice ?? 0 * project.components.inverter.quantity).toFixed(0)),
    po2q: project.components.inverter.quantity,
    po2p: inverterPrice,
    po3t: formatNumber((batteryPrice ?? 0 * project.components.battery.quantity).toFixed(0)),
    po3q: project.components.battery.quantity,
    po3p: batteryPrice,
    po4t: formatNumber((wallBoxPrice ?? 0 * project.components.wallbox.quantity).toFixed(0)),
    po4q:  project.components.wallbox.quantity,
    po4p: wallBoxPrice,
    po5t: formatNumber((heatPumpPrice ?? 0 * project.components.heatpump.quantity).toFixed(0)),
    po5q: project.components.heatpump.quantity,
    po5p: heatPumpPrice,
    solarPanelImage,
    inverterImage,
    batteryImage,
    heatPumpImage,
    wallBoxImage,
    panelName: solarPanel?.item?.name ?? cheapestSolarPanel?.name,
    pvm: solarPanel?.quantity,
    wsr: project.components?.inverter.quantity,
    inverterName: project.components?.inverter?.item?.name ?? cheapestinverters?.name,
    uks: "1",
    wsz: "1",
    gsl: "1",
    mdp: formatNumber(systemCapacity < 1 ? systemCapacity : Math.round(systemCapacity)),
    bt: ((project.components.battery?.item?.applicableMaxCapacity ?? 0) / 1000) || (cheapestbatteries?.applicableMaxCapacity ?? 0 / 1000),
    properties1: solarPanelPreference,
    properties2: inverterPreference,
    properties3: batteryPreference,
    properties4: heatpumpPreference,
    properties5: wallBoxesPreference,
    projectImages: combinedImaes,
    sl: solarPanelPrice?.toFixed(0),
    gb: formatNumberByLang(totalProjectCost.toFixed(0)),
    feedInTariff: formatNumberByLang(project.financialDetails.feedInTariff.toFixed(2)),
    isSolarPanelAdded,
    isInvertedAdded,
    isBatteryAdded,
    isHeatpumpAdded,
    isWallboxAdded,
    combinedDescription: combinedDescription.trim(),
  };

  return data;
};

export default useDownloadPdf;
