import { InstallerDB } from "src/db-types";

import { UserAuthStatus, UserState } from "./reducer/types";

import { RootState } from "src/redux/store";
import { JoinedWebinar, LanguagesShortCodes, MainUserState } from "src/redux/user";
import { RentingData } from "src/api";

export const getState = (state: RootState): UserState => state.user;
export const getUser = (state: RootState): MainUserState | null => state.user.user;
export const getInstaller = (state: RootState): InstallerDB | null => state.user.installer;
export const getRentingData = (state: RootState): RentingData | null => state.user.rantingData;
export const getJoinedWebinars = (state: RootState): JoinedWebinar | undefined =>
  state.user.user?.joinedWebinars;
export const getIsAuthLoading = (state: RootState): boolean => state.user.isAuthStateLoading;
export const getLangCode = (state: RootState): LanguagesShortCodes => state.user.language;
export const getAuthStatus = (state: RootState): UserAuthStatus => state.user.authStatus;
export const getSimplify = (state: RootState): boolean | undefined => state.user.simplify;
export const getSubsidy = (state: RootState): any => state.user.subsidy;
