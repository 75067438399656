import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Input, InputNumber, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import profile_pics from "src/assets/profile_pic/profile_pics.png";
import { PopupSkeleton } from "src/shared/components";
import SolarHubLogo from "src/assets/logos/SolarhubLogo.svg";
import * as S from "./SubSidy.styles";
import { useWindowDimensions } from "src/hooks";
import { PrimaryButton, SecondaryButton } from "src/shared/StyledComponents";
import { getQuote } from "src/redux/project/selectors";
import axios from "axios";
import { PopupActions, UserActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { closePopup, openPopup } from "src/redux/popups/action/action.creators";

export const SubSidi: React.FC = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [selectedSubsidies, setSelectedSubsidies] = React.useState<string[]>([]);
  const [manualSubsidy, setManualSubsidy] = React.useState<any>({});
  const [isManualSubsidy, setIsManualSubsidy] = React.useState<boolean>(false);
  const [subsidyOptions, setSubsidyOptions] = React.useState<{ label: string; value: any }[]>([]);
  const quote = useSelector(getQuote);
  const subsidyOptionsResponse = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchSubsidyOptions();
  }, [quote.mapboxAddress.city]);

  const fetchSubsidyOptions = useCallback(async () => {
    if (quote?.mapboxAddress?.city) {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/subsidy/search?searchTerm=${quote.mapboxAddress.city}`,
      );
      subsidyOptionsResponse.current = response.data.data.docs;
      setSubsidyOptions(
        response.data.data.docs.map((item: any) => ({ value: item._id, label: item.name })),
      );
    }
  }, [quote.mapboxAddress.city]);

  const handleSubsidyChange = useCallback((value) => {
    setSelectedSubsidies(value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (isManualSubsidy) {
      dispatch(UserActions.setSubsidy({ isManualSubsidy: true, subsidy: manualSubsidy }));
    } else {
      dispatch(
        UserActions.setSubsidy({
          isManualSubsidy: false,
          subsidy: selectedSubsidies.map((id: string) =>
            subsidyOptionsResponse.current?.find((subsidy: any) => subsidy._id === id),
          ),
        }),
      );
    }
    dispatch(PopupActions.closePopup(POPUP_KEYS.subsidy));
  }, [isManualSubsidy, selectedSubsidies, manualSubsidy, subsidyOptionsResponse.current]);

  const disableSubmit = useMemo(() => {
    if (isManualSubsidy) {
      if (manualSubsidy.energyType && manualSubsidy.subsidyType && manualSubsidy.subsidyAmount) {
        return false;
      }
      return true;
    } else {
      if (selectedSubsidies.length > 0) {
        return false;
      }
      return true;
    }
  }, [selectedSubsidies, manualSubsidy, isManualSubsidy]);

  const toggleManualSubsidy = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsManualSubsidy((prev) => !prev);
    },
    [setIsManualSubsidy],
  );

  useEffect(() => {
    setSelectedSubsidies([]);
    setManualSubsidy({});
  }, [isManualSubsidy]);

  return (
    <PopupSkeleton handleClose={() => dispatch(closePopup(POPUP_KEYS.subsidy))} open={true}>
      <S.MainContentWrapper>
        <img src={SolarHubLogo} style={{ height: 90 }} alt="Logo" />

        <S.Section1>
          <div>{t("Funding configurator")}</div>
          <div>
            <img src={profile_pics} style={{ height: 30 }} alt="profile_pics" />
          </div>
          <div>{t("Choose Your Funding")}</div>
          {!isManualSubsidy && (
            <div>
              <Select
                placeholder={t("Choose your subsidy")}
                style={{ width: 400 }}
                onChange={handleSubsidyChange}
                mode="multiple"
                options={subsidyOptions}
              />
            </div>
          )}
        </S.Section1>

        <S.Section2>
          {!isManualSubsidy && (
            <S.Button>
              <PrimaryButton
                style={{
                  width: "200px",
                  fontWeight: "800",
                }}
                onClick={toggleManualSubsidy}
              >
                Add Manual Subsidy
              </PrimaryButton>
            </S.Button>
          )}
          {isManualSubsidy && (
            <>
              <Select
                id="energyType"
                placeholder={t("Select energy type")}
                value={manualSubsidy.energyType}
                style={{ width: 400, height: 39, marginLeft: width < 700 ? "12px" : "unset" }}
                onChange={(value) =>
                  setManualSubsidy((prev: any) => ({ ...prev, energyType: value }))
                }
                options={[
                  { value: "Metergrid rental", label: "Metergrid rental " },
                  { value: "Metergrid buying", label: "Metergrid buying" },
                  { value: "own system", label: "own system" },
                ]}
              />
              <Select
                id="subsidyType"
                placeholder={t("Select subsidy type")}
                value={manualSubsidy.subsidyType}
                style={{ width: 400, height: 39, marginLeft: width < 700 ? "12px" : "unset" }}
                onChange={(value) =>
                  setManualSubsidy((prev: any) => ({ ...prev, subsidyType: value }))
                }
                options={[
                  { value: "PV system", label: "PV system " },
                  { value: "battery storage", label: "battery storage" },
                  { value: "heat pump", label: "heat pump" },
                ]}
              />
              <Input
                type="number"
                id="subsidyAmount"
                value={manualSubsidy.subsidyAmount}
                style={{
                  width: 400,
                  borderRadius: "20px",
                  marginLeft: width < 700 ? "12px" : "unset",
                  textAlign: "center",
                }}
                min={0}
                placeholder={t("Enter amount in €")}
                onChange={(e) => {
                  setManualSubsidy((prev: any) => {
                    return { ...prev, subsidyAmount: Number(e.target.value) };
                  });
                }}
              />

              <S.Button>
                <PrimaryButton
                  style={{
                    width: "200px",
                    fontWeight: "800",
                  }}
                  onClick={toggleManualSubsidy}
                >
                  Go Back
                </PrimaryButton>
              </S.Button>
            </>
          )}
          <S.Button>
            <SecondaryButton
              onClick={handleSubmit}
              style={{ width: "100px" }}
              disabled={disableSubmit}
            >
              Continue
            </SecondaryButton>
          </S.Button>
        </S.Section2>
      </S.MainContentWrapper>
    </PopupSkeleton>
  );
};

export default SubSidi;
