import LogoutIcon from "@mui/icons-material/Logout";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import PolicyIcon from "@mui/icons-material/Policy";
import { CircularProgress, ListItemIcon } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Provider, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ElectricitySupplyChart from "src/pdf/components/SupplyChart/ElectricitySupplyChart";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import { saveBlobToFile } from "src/utils";

// import { db } from "src/firebase-global";

// import { genrateReferralAndStoreInDatabase } from "src/api/FirebaseHelperMethods";
// import { addNewConfig } from "src/helpers/HeplerMothods";
// import { getCurrentUser, getProjectDetails, getReferralCode } from "src/helpers/Selector";
import { useURLData } from "src/hooks";

import { downloadProjectPdf } from "src/api/backend-endpoints";
import {
  NavMenuIcon,
  NavNewConfigIcon,
  NavReferralIcon,
  NavSavedOffersIcon,
} from "src/assets/svgs";
import { ApiAxiosClient } from "src/axios";
import { Mixpanel } from "src/mix-panel/mixpanel";
import * as trackingEvents from "src/mix-panel/trackingEvents";
import useDownloadPdf from "src/pages/ApplicationPage/hooks/useDownloadPdf";
import { imageUrlToBase64 } from "src/utils";

import { NavItemKey } from "../../../../constants/navItemKey";

import * as S from "./styles";

import { POPUP_KEYS, actionCreators as PopupActions } from "src/redux/popups";
import { openPopup } from "src/redux/popups/action/action.creators";
import { updateProject } from "src/redux/project/action/action.creators";
import { getAddress, getProject } from "src/redux/project/selectors";
import store, { RootState } from "src/redux/store";
import { UserAuthStatus, selectors as UserSelectors } from "src/redux/user";
import { getUser } from "src/redux/user/selectors";
import { InvestMentChart } from "src/popups/ChartsTabs/Investment/InvestMentChart/InvestMentChart";
import { MonthlyBalanceChart } from "src/popups/ChartsTabs/MonthlyBalance/MonthlyBalanceChart/MonthlyBalanceChart";
import SupplyChart from "src/pdf/components/SupplyChart/SupplyChart";
import LoadPdf from "src/pdf/LoadPdf";

const pdfURL = "https://solarhub-pdf.web.app/?pid=";
const pdfURLLocal = "http://localhost:3001/?pid=";

interface HeaderMenuProps {
  menuButtonClickHandler: Function;
}

const HeaderMenu: FC<HeaderMenuProps> = (props) => {
  const { menuButtonClickHandler } = props;

  const { t } = useTranslation();
  const mapboxAddress = useSelector(getAddress);
  const project = useSelector(getProject);
  const [renderChartsForPDF, setRenderChartsForPDF] = useState(false);

  const [investmentChartSS, setInvestmentChartSS] = useState<any>(null);
  const [monthlyChartSS, setMonthlyChartSS] = useState<any>(null);
  const [supplyChartSS, setSupplyChartSS] = useState<any>(null);
  const [electrictySupplyChart, setElectrictySupplyChart] = useState<any>(null);
  const chartTimer = useRef<any>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const currentUser = useSelector(UserSelectors.getUser);
  const userAuthStatus = useSelector(UserSelectors.getAuthStatus);
  const [query, setSearchParams] = useSearchParams();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const downloadPdf = query.get("pdf");

  const { isOnUserOverview } = useURLData();
  const installerDetails = useSelector(UserSelectors?.getInstaller);

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const pdfData = useDownloadPdf();

  const blobCallback = ({ blob, url, loading, error }: any): React.ReactNode => {
    if (url) {
      const pdfName = `Solarprojekt-SolarHub-${mapboxAddress?.address} ${mapboxAddress?.postcode}-${
        project?.shortId ? project?.shortId : ""
      }.pdf`;
      if (chartTimer.current) clearTimeout(chartTimer.current);
      chartTimer.current = setTimeout(() => {
        saveBlobToFile(url, pdfName);
        setRenderChartsForPDF(false);
        setAnchorEl(null);
      }, 5000);
    }
    return <div>There's something going on on the fly</div>;
  };

  const addDummyProject = useCallback(
    () => {
      // addNewConfig(
      //   project,
      //   pathname,
      //   setLoading,
      //   currentUser,
      //   savedprojectsVersion,
      //   quote,
      //   navigate,
      //   true,
      //   null,
      //   null,
      //   dispatch,
      // );
    },
    [
      // currentUser, dispatch, navigate, pathname, project, quote, savedprojectsVersion, setLoading
    ],
  );
  const open = Boolean(anchorEl);
  const { isOnSaved } = useURLData();

  const savedOffersBtnClickHandler = useCallback(() => {
    Mixpanel.track(trackingEvents.USER_CLICKS_ON_SAVED_OFFERS, {});
    menuButtonClickHandler(NavItemKey.OFFER);
    setAnchorEl(null);
  }, [menuButtonClickHandler]);

  const logoutBtnClickHandler = useCallback(() => {
    Mixpanel.track(trackingEvents.USER_CLICKS_ON_LOGOUT, {});
    menuButtonClickHandler(NavItemKey.LOGOUT);
    setAnchorEl(null);
  }, [menuButtonClickHandler]);

  // const pdfBtnClickHandler = useCallback(async() => {
    // setRenderChartsForPDF(true);
    // const pdfName = `Solarprojekt-SolarHub-${mapboxAddress?.address} ${mapboxAddress?.postcode}-${
    //   project?.shortId ? project?.shortId : ""
    // }`
    // try {
    // const base64Image = await imageUrlToBase64(pdfData.projectImage);
    // const response = await ApiAxiosClient.post(`${downloadProjectPdf.url}/?templateId=${process.env.REACT_APP_TEMPLATE_ID}&fileName=${pdfName}`, {...pdfData, projectImage: base64Image}) 
    // const url = response.data.url;
    // const a = document.createElement("a");
    // a.href = url;
    // a.download = pdfName;
    // a.click();
    // setRenderChartsForPDF(false);
    // dispatch(updateProject({ ...project, projectPdf: url }));
    // } catch (error) {
    //   setRenderChartsForPDF(false);
    // }
  // }, [dispatch, mapboxAddress?.address, mapboxAddress?.postcode, pdfData, project]);

  const pdfBtnClickHandler = useCallback(() => {
    setRenderChartsForPDF(true);
  }, []);
  useEffect(() => {
    if (downloadPdf) {
      pdfBtnClickHandler();
    }
  }, [downloadPdf, pdfBtnClickHandler]);

  const referralBtnClickHandler = useCallback(() => {
    if (!user) {
      dispatch(openPopup(POPUP_KEYS.auth));
    } else if (!project.quoteId) {
      dispatch(openPopup(POPUP_KEYS.welcome_address));
    } else {
      user.refCode.find((refDetails) => refDetails.quoteId === project.quoteId);
    }
  }, [user, project.quoteId, dispatch]);

  const policyButtonClickHandler = useCallback(() => {
    window.open(
      installerDetails?.dataPrivacyURL || "https://www.solarhub24.de/Datenschutz",
      "_blank",
    );
  }, []);

  const addConfigBtnClickHandler = useCallback(() => {
    Mixpanel.track(trackingEvents.USER_CLICKS_ON_ADD_ADDITIONAL_CONFIGURATION, {});
    menuButtonClickHandler(NavItemKey.ADD_CONFIGURATION);
    setAnchorEl(null);
  }, [menuButtonClickHandler]);

  const handleSaveProjectUserDetailPopupOpen = useCallback(() => {
    dispatch(PopupActions.openPopup(POPUP_KEYS.saved_project_user_info));
  }, [dispatch]);

  const shouldShowAddConfigBtn = Boolean(currentUser && window.location.pathname.includes("saved"));

  return (
    <S.MainContainer>
      <NavMenuIcon className="pointer" onClick={(event) => handleClick(event)} />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleSaveProjectUserDetailPopupOpen} disabled={!isOnSaved}>
          <ListItemIcon>
            <PersonOutlineOutlinedIcon />
          </ListItemIcon>
          <S.CustomListItemText>{t("users")}</S.CustomListItemText>
        </MenuItem>

        {!isOnUserOverview && (
          <MenuItem onClick={savedOffersBtnClickHandler}>
            <ListItemIcon>
              <NavNewConfigIcon />
            </ListItemIcon>
            <S.CustomListItemText>{t("saved offers")}</S.CustomListItemText>
          </MenuItem>
        )}

        {shouldShowAddConfigBtn && (
          <MenuItem onClick={addConfigBtnClickHandler}>
            <ListItemIcon>
              <NavSavedOffersIcon />
            </ListItemIcon>
            <S.CustomListItemText>{t("Add aditional configruation")}</S.CustomListItemText>
          </MenuItem>
        )}

        <MenuItem onClick={referralBtnClickHandler}>
          <ListItemIcon>
            <NavReferralIcon />
          </ListItemIcon>
          <S.CustomListItemText>{t("Customer referral")}</S.CustomListItemText>
        </MenuItem>

        {!isOnUserOverview && (
          <MenuItem onClick={pdfBtnClickHandler} disabled={renderChartsForPDF}>
            <ListItemIcon>
              {!renderChartsForPDF && <PictureAsPdfOutlinedIcon />}
              {renderChartsForPDF && <CircularProgress size={20} />}
            </ListItemIcon>
            <S.CustomListItemText>
              {
                <>
                  {renderChartsForPDF && t("Saving Project as a PDF")}
                  {!renderChartsForPDF && t("Generate Pdf")}
                </>
              }
            </S.CustomListItemText>
          </MenuItem>
        )}

{renderChartsForPDF && (
          <div
            style={{
              position: "fixed",
              top: 10000000,
              left: 1000000,
            }}
          >
            <InvestMentChart setScreenshot={setInvestmentChartSS} />
            <MonthlyBalanceChart setScreenshot={setMonthlyChartSS} />
            <SupplyChart setScreenshot={setSupplyChartSS} />
            {/* */}
            <ElectricitySupplyChart setScreenshot={setElectrictySupplyChart} />
            <BlobProvider
              document={
                <Provider store={store}>
                  <LoadPdf
                    investmentChartSS={investmentChartSS}
                    monthlyChartSS={monthlyChartSS}
                    electrictySupplyChart={electrictySupplyChart}
                    supplyChartSS={supplyChartSS}
                  />
                </Provider>
              }
            >
              {blobCallback}
            </BlobProvider>
          </div>
        )}

        <MenuItem onClick={policyButtonClickHandler}>
          <ListItemIcon>
            <PolicyIcon />
          </ListItemIcon>
          <S.CustomListItemText>{t("Data Protection")}</S.CustomListItemText>
        </MenuItem>

        {userAuthStatus === UserAuthStatus.loggedIn && (
          <MenuItem onClick={logoutBtnClickHandler}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <S.CustomListItemText>{t("Logout")}</S.CustomListItemText>
          </MenuItem>
        )}
      </Menu>
    </S.MainContainer>
  );
};

export default HeaderMenu;
