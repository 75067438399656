import { clone, isNull } from "lodash";

// import { InstallerDB } from "src/db-types";

import { UserState, actionCreators } from "src/redux/user";

export const setSubsidy = (
  currentState: UserState,
  { payload }: ReturnType<typeof actionCreators.setUser>,
): UserState => {
  const newState = clone(currentState);
  let subsidy: any | null = null;

  if (!isNull(payload)) {
    subsidy = payload;
  }

  newState.subsidy = subsidy;
  return newState;
};
